export default {
	typeOptions: [{
			label: '机构',
			value: 1,
		},
		{
			label: '客服',
			value: 2,
		}
	]
}
