import request from '@/utils/request'

// 套餐包列表
export function getPatientList(params) {
  return request({
    url: '/admin/patient/patientList',
    method: 'GET',
    params
  })
}


// 医师列表(已配置服务包)
export function getDoctorDemandList(params) {
  return request({
    url: '/admin/patient/doctorDemandList',
    method: 'GET',
    params
  })
}

// 服务包列表, 传入有服务包医生的id
export function getDemandList(id, params) {
  return request({
    url: `/admin/patient/demandList/${id}`,
    method: 'GET',
    params
  })
}

// 发放服务包
export function postPatientSendDemand(id, data) {
  return request({
    url: `/admin/patient/sendDemand/${id}`,
    method: 'POST',
    data
  })
}

// 导入患者档案
export function postPatientImport(data) {
  return request({
    url: `/admin/patient/import`,
    method: 'POST',
    // headers:{'Content-Type':'multipart/form-data'},
    data
  })
}

// 同步数据
export function postPatientSyncContract(data) {
  return request({
    url: `/admin/patient/syncContract`,
    method: 'POST',
    data
  })
}

// 商户列表
export function getMerchants(id, params) {
  return request({
    url: 'admin/merchants',
    method: 'GET',
    params
  })
}

export function getStaff(id, params) {
  return request({
    url: 'admin/getStaff/' + id,
    method: 'GET',
    params
  })
}

export function saveContract(id, data) {
  return request({
    url: 'admin/saveContract/' + id,
    method: 'post',
    data
  })
}

//线索分配
export function getClueList(data) {
  return request({
    url: 'admin/assignThread',
    method: 'POST',
    data
  })
}
//导入线索
export function getImportThread(data) {
  return request({
    url: 'admin/importThread',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}
//导入档案线索
export function getImportContractMThread(data) {
  return request({
    url: 'admin/importThreadContractM',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}

// 自定义导入线索
export function postCustomImportThread(data) {
  return request({
    url: 'admin/thread/import',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}


//档案详情
export function getPatientInfo(id, params) {
  return request({
    url: 'admin/patient/info/' + id,
    method: 'GET',
    // headers: { 'Content-Type': 'multipart/form-data' },
    params,
  })
}
//修改档案
export function putUpdate(id, data) {
  return request({
    url: 'admin/patient/update/'+ id,
    method: 'PUT',
    // headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}
//回访级别

export function getVisitLevel(params) {
  return request({
    url: 'admin/common/visitLevel',
    method: 'GET',
    params,
  })
}
//获取客户列表
export function getCustomerList(params) {
  return request({
    url: 'admin/common/getStaff',
    method: 'GET',
    params
  })
}
//删除患者档案

export function DeldelContractM(data) {
  return request({
    url: 'admin/delContractM/',
    method: 'DELETE',
    data
  })
}

// 导出
export function getPatientExport(params) {
  return request({
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob',
    url: 'admin/exportContractM',
    method: 'GET',
    params
  })
}

